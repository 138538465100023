import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

import { makeMsalConfig } from "./makeMsalConfig";

export function makeMsalInstance() {
  const msalInstance = new PublicClientApplication(makeMsalConfig());

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      msalInstance.setActiveAccount(event.payload as AccountInfo);
    }
  });

  return msalInstance;
}
