import { Box, Button, Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";

type ErrorPageContentType = {
  graphic: ReactNode;
  title: string;
  description: string;
  buttonTitle: string;
  onButtonClick: () => void;
};

export function ErrorPageContent({
  graphic,
  title,
  description,
  buttonTitle,
  onButtonClick,
}: ErrorPageContentType): ReactElement {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box mt={3} mb={1}>
        {graphic}
      </Box>
      <Box mb={3} sx={{ textAlign: "center", maxWidth: 620 }}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      </Box>
      <Box mb={3}>
        <Button variant="contained" color="primary" onClick={onButtonClick}>
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
}
