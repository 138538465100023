import { theme } from "@dfp/mui-theme";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { AppLanguageProvider } from "./Context/AppLanguage";
import { reactPlugin } from "./Features/AppInsights/AppInsightsService";
import { makeMsalInstance } from "./Features/MSAuthLibrary/makeMsalInstance";
import "./I18n/i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const msalInstance = makeMsalInstance();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppLanguageProvider>
              <App pca={msalInstance} />
            </AppLanguageProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ErrorBoundary>
    </AppInsightsContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
