import { Alert } from "@mui/material";
import { isAxiosError } from "axios";
import { isEmpty } from "lodash-es";

import { isPlatformRequestError } from "../../Context/API/utils";
import { Error401Content } from "../../Features/ErrorPages/Error401Content";
import { Error404Content } from "../../Features/ErrorPages/Error404Content";
import { Error500Content } from "../../Features/ErrorPages/Error500Content";
import { useErrorTranslator } from "../../Hooks/Error";

type SplashErrorProps = {
  error: Error;
};

export function SplashError({ error }: SplashErrorProps) {
  const et = useErrorTranslator();

  if (isPlatformRequestError(error, 400, undefined, "DocumentNotFound")) {
    return <Error404Content />;
  }

  if (
    isPlatformRequestError(error, 401) ||
    isPlatformRequestError(error, 400, "EntitlementError") ||
    isRequestorIdentityPartyRoleTypeError(error)
  ) {
    return <Error401Content />;
  }

  if (isAxiosError(error)) {
    return <Error500Content />;
  }

  return (
    <Alert sx={{ marginBottom: 2 }} severity="error">
      {et(error)}
    </Alert>
  );
}

function isRequestorIdentityPartyRoleTypeError(err: Error) {
  return (
    isPlatformRequestError(err, 400) &&
    !isEmpty(
      err.response?.data?.errorAttributes?.validationErrors?.find(
        (validationError: FIXME) =>
          validationError.errorType === "WrongEnumValue" &&
          validationError.errorAttributes?.attributePath ===
            "requestorIdentity.requestor.partyRoleReference.partyRoleType"
      )
    )
  );
}
